import React from 'react';
import OrderSelect from './OrderSelect';
import Reevaluate from './Reevaluate';
import SimulationTime from './SimulationTime';
import logFetchError from './Functions/LogFetchError'
import fetchSubmitLogs from './Functions/FetchSubmitLogs'
import MDSpinner from 'react-md-spinner';

class OrderPopup extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			orderInput: '',
			orderPopup: 'writeOrder',// track which order popup is displayed
			frequencyNumber: 0,
			frequencyUnit: 'hours',
			selectedStart: 'now',
			orderVerification: {},
			selectedRoute: '',
			validateOrderErrorText: '',
			validateOrderErrorFunction: null,
			prereqInfo: {},
			prereqSelected: 0,
			selectedOrders: [],
			focusedOrder: {},
			inputArray: [],
			orderVerificationError: '',
			ordersPopupLoading: false,
			validateLoading: false,
			fetchPrerequisiteOrderOutstanding: false,
		}

		this.validateClick = this.validateClick.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.setOrderTime = this.setOrderTime.bind(this);
		this.closeOrderTime = this.closeOrderTime.bind(this);
		this.selectStart = this.selectStart.bind(this);
		this.frequencyUnitChange = this.frequencyUnitChange.bind(this);
		this.frequencyNumberChange = this.frequencyNumberChange.bind(this);
		this.selectQualifier = this.selectQualifier.bind(this);
		this.displayOrderQualifiers = this.displayOrderQualifiers.bind(this);
		this.broadenSearch = this.broadenSearch.bind(this);
		this.orderSelectClick = this.orderSelectClick.bind(this);
		this.handleOrderInput = this.handleOrderInput.bind(this);
		this.fetchOrders = this.fetchOrders.bind(this);

		this.textArea = React.createRef();
	}

	validateClick() {
		let routes = this.state.focusedOrder.ROUTE.split('~');
		let foundFlag = routes.length === 0 ? true : false
		for (let i = 0; i < routes.length; ++i) {
			if (routes[i] === this.state.selectedRoute) {
				foundFlag = true
				break
			}
		}
		if (foundFlag) {
			this.setState({selectRouteError: false})
			const nextPopupFunction = () => {
				if (this.state.inputArray && this.state.inputArray.length > 0) {
					let temp = this.state.inputArray;
					let order = temp.shift();
					this.setState({
						inputArray: temp,
					});
					this.fetchOrders(order);
				}
				else {
					this.props.closePopup();
				}
			}
			this.setState({validateLoading: true})
			this.props.fetchValidate(this.state.focusedOrder.ID, this.state.selectedFrequency, this.state.selectedRoute, this.state.orderTime, (nextPopup, error) => {
				this.setState({validateLoading: false})
				if (nextPopup === 'prereqsRequired') {
					let output = error.output
					output = output.replace('{', '')
					output = output.replace('}', '')
					let prereqInfo = {
						output: output,
						options: error.PrerequisiteOut
					}
					this.setState({orderPopup: 'prereqsRequired', prereqInfo, prereqOrder: error})
				} else if (nextPopup === 'orderError') {
					this.setState({orderPopup: 'validateOrderError', validateOrderErrorText: error, validateOrderErrorFunction: nextPopupFunction})
				} else if (nextPopup === 'consultation') {
					this.setState({orderPopup: 'consultation'})
				} else {
					nextPopupFunction()
				}				
			}, () => this.setState({validateLoading: false}));
		} else {
			this.setState({selectRouteError: true})
		}
	}

	closePopup() {
		if (!this.state.inputArray || this.state.inputArray.length === 0) {
			this.props.closePopup();
		}
		else {
			let temp = this.state.inputArray;
			let order = temp.shift();
			this.fetchOrders(order);
			this.setState({
				inputArray: temp,
			})
		}
	}

	componentDidMount() {
		this.textArea.current.focus();
	}

	setOrderTime(selectedTime, minimumTime) {
		// order time is set to seconds plus the current time
		this.setState({
			orderTime: {
				days: selectedTime.days,
				hours: selectedTime.hours,
				minutes: selectedTime.minutes,
			},
			orderPopup: 'orderQualifiers',
		});
	}

	closeOrderTime() {
		this.setState({
			orderPopup: 'orderQualifiers',
			selectedStart: 'now',
		});
	}

	selectStart(option) {
		if (option === 'later') {
			// set the orderPopup to reevaluate
			this.setState({orderPopup: 'orderTime'});
		}
		else {
			this.setState({orderTime: 0});
		}
		this.setState({selectedStart: option});
	}

	frequencyUnitChange(event) {
		this.setState({frequencyUnit: event.target.value});
	}

	frequencyNumberChange(event) {
		let frequency = event.target.value

		this.setState({frequencyNumber: frequency});

		if (this.state.frequencyUnit === 'hours') {
			frequency = frequency * 60;
		}
		else if (this.state.frequencyUnit === 'days') {
			frequency = frequency * 1440;
		}
		this.setState({selectedFrequency: frequency});
	}

	selectQualifier(mode, option) {
		if (mode === 'frequency') {
			this.setState({selectedFrequency: option});
		}
		else if (mode === 'route') {
			this.setState({selectedRoute: option});
		}
	}

	displayOrderQualifiers() {
		if (this.state.selectedOrders.length > 0) {
			let focusedOrder = this.state.selectedOrders[0]
			let inputArray = this.state.inputArray ? [...this.state.inputArray] : []
			if (this.state.selectedOrders.length > 1) {
				for (let i = 1; i < this.state.selectedOrders.length; ++i) {
					inputArray.unshift(this.state.selectedOrders[i].original)
				}
				this.setState({inputArray})
			}
			this.setState({focusedOrder, selectedOrders: [], orderVerificationError: ''})
			let routes = focusedOrder.ROUTE.split('~');
			if (routes.length === 1) {
				this.setState({selectedRoute: routes[0]})
			} else {
				this.setState({selectedRoute: ''})
			}
			this.setState({orderPopup: 'orderQualifiers'});
			// TODO check to see if order is already in order sheet, if so error popup
			for (let i = 0; i < this.props.orderSheet.length; i++) {
				if (focusedOrder.ID === this.props.orderSheet[i].orderId) {
					// there is a repeat order display popup
					this.setState({orderPopup: 'repeatOrder'});
				}
			}
		} else {
			this.setState({orderVerificationError: 'Select at least 1 order'})
		}
	}

	broadenSearch() {
		let search = this.state.orderInput.substring(0, 3);
		if (this.state.uniqueOrders) {
			let uniqueOrders = [...this.state.uniqueOrders]
			uniqueOrders.push(search)
			this.setState({orderInput: search, uniqueOrders}, () => {
				this.fetchOrders(search);
			});
		} else {
			this.setState({orderInput: search}, () => {
				this.fetchOrders(search);
			});
		}
	}

	orderSelectClick(id) {
		let selectedOrders = this.state.selectedOrders.length > 0 ? [...this.state.selectedOrders] : []
		let selectedOrderIndex = -1

		//Check if there are currently any selected orders
		if (selectedOrders.length > 0) {
			//Check to see if any orders match the order that is being selected. If an order matches, we set the
			//selectedOrderIndex to that order's index and the .some method returns true.
			if (selectedOrders.some((selectedOrder, index) => {
				if (selectedOrder === id) {
					selectedOrderIndex = index
				}
				return selectedOrder === id
			})) {
				//Remove the selected order
				selectedOrders = selectedOrders.slice(0, selectedOrderIndex).concat(selectedOrders.slice(selectedOrderIndex + 1, selectedOrders.length))
			} else {
				//Verify that the order does not already exist in selectedOrders
				if (selectedOrders.every((selectedOrder) => selectedOrder.original !== id.original)) {
					selectedOrders.push(id)
				}
			}
		} else {
			selectedOrders.push(id)
		}
		this.setState({selectedOrders});
	}

	handleOrderInput(event) {
		this.setState({orderInput: event.target.value});
	}

	getFirstOrderInInput = () => {
		let input = this.state.orderInput;
		let tempInputList = input.split('\n');
		let inputArray = []
		tempInputList.forEach((inputValue) => {
			if (inputValue.trim() !== '') {
				inputArray.push(inputValue)
			}
		})
		let orderInput = ''
		let firstOrder = inputArray.shift()
		this.setState({inputArray, orderInput})
		return firstOrder
	}

	fetchOrders(input) {
		if (!this.props.fetchOutstanding) {
			let order = typeof input === 'string' ? input : this.getFirstOrderInInput()
	
			order = order.trim();
			if (this.props.orderList) {
				if (this.props.orderList.some((orderListValue) => orderListValue.NAME === order)) {
					return this.setState({orderPopup: 'repeatOrder', focusedOrder: this.state.selectedOrders[0]});
				}
			}
	
	
			if (order !== '') {
				this.setState({ordersPopupLoading: true})
				fetch(`${this.props.route}/orders.webapi`, {
					method: 'POST',
					headers: {
						'Token': this.props.userData.Token,
						'Content-Type': 'text/plain',
					},
					body: JSON.stringify({
						customerId: this.props.userData.CustomerId,
						caseguid: this.props.simulationData.caseguid,
						orderString: order
					})
				})
				.then(response => {
					//Attempt sending logs
					fetchSubmitLogs(this.props.userProfile, this.props.userData)
	
					if (response.status === 401) {
						this.props.setAuthenticationError()
						throw new Error('Authentication Error')
					} else {
						return response.text()
					}
				})
				.then(response => {
					let result = response
					this.props.simulateNetworkLag();
					if (result === '{Error: Not recognized by clerk. Enter using different wording}') {
						return this.setState({orderPopup: 'orderError', orderInput: order, ordersPopupLoading: false})
					}
					let parsedResults = JSON.parse(result)
					let selectedOrders = []
					if (parsedResults.OrderResult.length === 1) {
						selectedOrders = [parsedResults.OrderResult[0]]
					}
					this.setState({
						selectedFrequency: null,
						orderVerification: parsedResults,
						selectedOrders,
						orderPopup: 'orderVerification',
						orderInput: order,
						frequencyNumber: 0,
						ordersPopupLoading: false
					});
				})
				.catch(error => {
					console.log('error', error);
		
					// when there is a bad input display the error popup
					this.setState({orderPopup: 'orderError', ordersPopupLoading: false});
	
					logFetchError(error, this.props.userProfile, this.props.userData, 'fetchOrders')
		
					// if ((error.toString().includes(('SyntaxError: Unexpected token U in JSON at position 1')) || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) && !orderError) {
					// 	this.props.setAuthenticationError()
					// }
				});
			} else {
				this.setState({orderPopup: 'orderError'});
			}
		}
	}

	fetchPrerequisiteOrder = async (order) => {
		this.setState({fetchPrerequisiteOrderOutstanding: true})
		await this.props.fetchPrerequisiteOrder(this.state.prereqInfo.options[this.state.prereqSelected].ID, this.state.prereqOrder.ID, this.closePopup)
		this.setState({fetchPrerequisiteOrderOutstanding: false})
	}


	render () {

		let orderPopup;
		if (this.state.orderPopup === 'writeOrder') {

			let error;
			if (this.state.orderInputError) {
				error = <div className='order-error'>{' Invalid input'}</div>
			}

			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<div className='popup-header'>Order Input {error}</div>
						<div className='popup-content'>
							<textarea
								className='order-input'
								value={this.state.orderInput}
								onChange={this.handleOrderInput}
								ref={this.textArea}
							/>
						</div>
						{this.state.ordersPopupLoading ?
							<div className='options-footer-loading-container'>
								<MDSpinner
									size={25}
									singleColor={this.props.primaryColor}
								/>
							</div>
						:
							<div className='options-footer'>
								<input className='simulation-button button-gap' type='button' value='Confirm Orders' onClick={this.fetchOrders.bind(this)}/>
								<input className='simulation-button' type='button' value='Cancel' onClick={this.closePopup}/>
							</div>
						}
					</div>
				</div>
			)
		}
		else if (this.state.orderPopup === 'orderVerification') {
			if (!this.state.selectedOrders) {
				this.setState({selectedOrders: []});
			}
			let orderList = this.state.orderVerification.OrderResult.map((order, index) =>
				<OrderSelect
					data={order}
					key={index}
					orderSelectClick={this.orderSelectClick}
					// selectedOrder={this.state.selectedOrder}
					selectedOrder={this.state.selectedOrders.length > 0 ? this.state.selectedOrders.some((value) => value.original === order.original) : false}
				/>
			);

			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<div className='popup-header'>Order Verification {this.state.orderVerificationError !== '' && <p style={{color: 'red', margin: 0, marginLeft: 5}}>{this.state.orderVerificationError}</p>}</div>
						<div className='popup-content'>
							<div className='popup-element'>{this.state.orderInput}</div>
							<div className='table-content'>
								{orderList}
							</div>
						</div>
						<div className='options-footer'>
							<div className='verification-buttons-left'>
								<input className='simulation-button button-gap' type='button' value={`Confirm Orders (${this.state.selectedOrders.length})`} onClick={this.displayOrderQualifiers}/>
							</div>
							<div className='verification-buttons-right'>
								<input className='simulation-button' type='button' value='Cancel' onClick={this.closePopup}/>
								<div className='verification-buttons-left'>
									<input className='simulation-button' type='button' value='Broaden Search' onClick={this.broadenSearch}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.orderPopup === 'orderQualifiers') {
			// the order is a medication

			let now;
			let later;
			if (this.state.selectedStart === 'now') {
				now = <SimulationTime simulationTime={this.props.simulationTime} dayWeek={true}/>;
			}
			else {
				later = <SimulationTime simulationTime={this.state.orderTime} dayWeek={true}/>;
			}

			let start;
			if (this.props.endCase) {
				start = (
					<div className='end-case-qualifier'>
						<div className='popup-header'>{'Start'}</div>
						<div className='qualifier-checkbox'>
							<input type='checkbox' checked={this.state.selectedStart === 'now'} onClick={this.selectStart.bind(this, 'now')}/>
							<label className='simulation-time white-space'>{'Now '}{now}</label>
						</div>
						<div className='qualifier-checkbox'>
							<input type='checkbox' checked={this.state.selectedStart === 'later'} onClick={this.selectStart.bind(this, 'later')}/>
							<label className='simulation-time white-space'>{'Later '}{later}</label>
						</div>
						<div className='qualifier-checkbox'>
							<input className='simulation-button' type='button' value='Change Order Time' onClick={this.selectStart.bind(this, 'later')}/>
						</div>
					</div>
				);
			}

			let qualifiers;
			if (this.state.focusedOrder?.FREQUENCY && this.state.focusedOrder?.ROUTE) {

				let frequencyArray = this.state.focusedOrder.FREQUENCY.split('~');
				if (!this.state.selectedFrequency) {
					this.setState({selectedFrequency: frequencyArray[0]});
				}
				let frequency = frequencyArray.map((option) =>
					<div className='qualifier-checkbox'>
						<input type='checkbox' checked={this.state.selectedFrequency === option} onClick={this.selectQualifier.bind(this, 'frequency', option)}/>
						<label>{option}</label>
					</div>
				);

				let routeArray = this.state.focusedOrder.ROUTE.split('~');

				let route = routeArray.map((option) =>
					<div className='qualifier-checkbox'>
						<input type='checkbox' checked={this.state.selectedRoute === option} onClick={this.selectQualifier.bind(this, 'route', option)}/>
						<label>{option}</label>
					</div>
				);

				qualifiers = (
					<div className='end-case-qualifiers'>
						<div className='popup-content'>
							<div className='qualifier-element'>
								<div className='popup-header'>Frequency</div>
								{frequency}
							</div>
							<div className='qualifier-element'>
								<div className='popup-header'>Route of Administration</div>
								{route}
							</div>
						</div>
						{start}
					</div>
				)
			}
			else {
				qualifiers = (
					<div className='end-case-qualifiers'>
						<div className='popup-content'>
							<div className='qualifier-element'>
								<div className='popup-header'>{'Frequency - (optional)'}</div>
								<div className='qualifier-checkbox'>
									{'Every: '}
									<input className='qualifier-input' type='number' min={0} max={1000} value={this.state.frequencyNumber} onChange={this.frequencyNumberChange}/>
									<select className='qualifier-select' value={this.state.frequencyUnit} onChange={this.frequencyUnitChange}>
										<option value='hours'>HOURS</option>
										<option value='days'>DAYS</option>
									</select>
								</div>
							</div>
						</div>
						{start}
					</div>
				)
			}

			let selectedOrder = this.state.focusedOrder

			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup' style={{minHeight: '500px'}}>
						<div className='popup-header'>{'Order Qualifiers - ' + selectedOrder?.original} {this.state.selectRouteError && <p className='routeError'>You need to select a Route of Administration</p>}</div>
						{qualifiers}
						{this.state.validateLoading ?
							<div className='options-footer-loading-container'>
								<MDSpinner
									size={25}
									singleColor={this.props.primaryColor}
								/>
							</div>
						:
							<div className='options-footer'>
								<input className='simulation-button button-gap' type='button' value='OK' onClick={this.validateClick}/>
								<input className='simulation-button' type='button' value='Cancel' onClick={this.closePopup}/>
							</div>
						}
					</div>
				</div>
			)
		}
		else if (this.state.orderPopup === 'orderTime') {
			orderPopup = (
				<Reevaluate
					simulationTime={this.props.simulationTime}
					fetchAdvance={this.setOrderTime}
					closePopup={this.closeOrderTime}
					advanceUpdate={false}
					endCase={true}
				/>
			);
		}
		else if (this.state.orderPopup === 'orderError') {
			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<div className='popup-header'>Unrecognized Entry</div>
						<div className='popup-content'>
							<div className='popup-element'>
								<div>{`${this.state.orderInput} not recognized by clerk.`}</div>
							</div>
							{this.state.orderInput.length < 3 && <div className='popup-element'>
								<div>{'Hint (hints will not be given on test day): '}</div>
								<div>{'Orders need to have at least 3 characters to search.'}</div>
							</div>}
						</div>
						<div className='popup-footer'>
							<input className='simulation-button' type='button' value='OK' onClick={this.closePopup}/>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.orderPopup === 'repeatOrder') {
			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<div className='popup-header'>Order Selection Error</div>
						<div className='popup-content'>
							<div className='popup-element'>
								<div>{`You may not order ${this.state.focusedOrder.original} more than once.`}</div>
							</div>
						</div>
						<div className='popup-footer'>
							<input className='simulation-button' type='button' value='OK' onClick={this.closePopup}/>
						</div>
					</div>
				</div>
			)
		} else if (this.state.orderPopup === 'validateOrderError') {
			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<div className='popup-header'>Order Error</div>
						<div className='popup-content'>
							<div className='popup-element'>
								<p className='popup-error-text'>{this.state.validateOrderErrorText}</p>
							</div>
						</div>
						<div className='popup-footer'>
							<input className='simulation-button' type='button' value='OK' onClick={this.state.validateOrderErrorFunction}/>
						</div>
					</div>
				</div>
			)
		} else if (this.state.orderPopup === 'consultation') {
			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<p className='popup-header'>Reason for Consultation</p>
						<div className='popup-content'>
							<div className='popup-element'>
								<div>{'Please enter your reason for consultation in 10 words or less.'}</div>
							</div>
							<textarea className='order-input'/>
						</div>
						<div className='popup-footer'>
							<input className='simulation-button' type='button' value='OK' onClick={this.closePopup}/>
						</div>
					</div>
				</div>
			)
		} else if (this.state.orderPopup === 'prereqsRequired') {
			orderPopup = (
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<p className='popup-header'>Prerequisite Order</p>
						<div className='popup-content'>
							<div className='popup-element'>
								<div>{this.state.prereqInfo.output}</div>
							</div>
							<div className='prereqWrapper'>
								{this.state.prereqInfo.options.map((prereq, prereqIndex) => (
									<div className={`prereqContainer ${this.state.prereqSelected === prereqIndex && 'prereqSelected'}`} onClick={() => this.setState({prereqSelected: prereqIndex})}>
										<p className='prereqText'>{prereq.NAME}</p>
									</div>
								))}
							</div>
						</div>
						{this.state.fetchPrerequisiteOrderOutstanding ?
							<div className='options-footer-loading-container'>
								<MDSpinner
									size={25}
									singleColor={this.props.primaryColor}
								/>
							</div>
						:
							<div className='popup-footer'>
								<input className='simulation-button' type='button' value='OK' style={{marginRight: '10px'}} onClick={() => this.fetchPrerequisiteOrder()}/>
								<input className='simulation-button' type='button' value='Cancel' onClick={this.closePopup}/>
							</div>
						}
					</div>
				</div>
			)
		}

		return (
			<div className='popup-container'>
				{orderPopup}
			</div>
		)
	}
}

export default OrderPopup;
