import React from 'react'
import CaseList from './CaseList'
import Simulation from './Simulation'
import Grading from './Grading'
import './index.css'
import TermsOfService from './TermsOfService'
import { UnauthenticatedHandler } from './UnauthenticatedHandler'
import formatCompletedOrders from './Functions/IncompleteStart/formatCompletedOrders'
import formatCurrentOrders from './Functions/IncompleteStart/formatCurrentOrders'
import getActionLogData from './Functions/IncompleteStart/getActionLogData'
import logo from './images/cssCases.png'
import logFetchError from './Functions/LogFetchError'
import fetchSubmitLogs from './Functions/FetchSubmitLogs'
import './App.css'
import Spinner from 'react-md-spinner'
import getDaysFromDate from './Functions/GetDaysFromDate'
import ReportCard from './ReportCard/ReportCard'
import NewCaseList from './NewCaseList/NewCaseList'
import AccountSettings from './AccountSettings/AccountSettings'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import StaticJsonCaseList from './static_cases_info.json'
import ConfirmationPopup from './Util/ConfirmationPopup/ConfirmationPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import LoginPage from './NewLogin/LoginPage'
import NewCaseSelectorPopup from './NewCaseSelectorPopup/NewCaseSelectorPopup'
import NewGrading from './NewGrading/NewGrading'
import NewUnauthenticatedPopup from './Util/NewUnauthenticatedPopup/NewUnauthenticatedPopup'
import { faStethoscope, faBrain, faTruckMedical, faChild, faVenus } from '@fortawesome/free-solid-svg-icons'
import { faHeadSideBrain } from '@fortawesome/pro-solid-svg-icons'

const THEMES = [
	{
		themeTitle: 'CCS Cases Classic',
		themeDescription: 'Our personal favorite - A modest design with high contrast accents',
		primaryColor: '#0B335D',
		secondaryColor: '#F79D23',
		primaryBackgroundColor: '#e7e7e7',
		secondaryBackgroundColor: '#ffffff',
		tertiaryBackgroundColor: '#3E3E3E',
		offBackgroundColor: '#cecece',
		textColor: '#1d1d1d',
		caseTextColor: '#1d1d1d',
		secondaryTextColor: '#ffffff',
		offTextColor: '#686868',
		secondaryOffTextColor: '#cecece',
		tertiaryOffTextColor: '#1d1d1d',
		caseSelectedColor: '#e5ebf3',
	},
	{
		themeTitle: 'CCS After Dark',
		themeDescription: 'A dark mode variant of our classic theme for the night owls out there.',
		primaryColor: '#3670ae',
		secondaryColor: '#F79D23',
		primaryBackgroundColor: '#252525',
		secondaryBackgroundColor: '#3c3c3c',
		tertiaryBackgroundColor: '#4a4a4a',
		offBackgroundColor: '#cecece',
		textColor: '#ffffff',
		caseTextColor: '#ffffff',
		secondaryTextColor: '#ffffff',
		offTextColor: '#a4a4a4',
		secondaryOffTextColor: '#cecece',
		tertiaryOffTextColor: '#ffffff',
		caseSelectedColor: '#454b53',
	},
	{
		themeTitle: 'CCS Staycation',
		themeDescription: 'Even doctors need a break. We thought we\'d bring the beach to you - You\'re welcome.',
		primaryColor: '#266595',
		secondaryColor: '#0e4976',
		primaryBackgroundColor: '#8b7555',
		secondaryBackgroundColor: '#ead6b6',
		tertiaryBackgroundColor: '#caac81',
		offBackgroundColor: '#ccb188',
		textColor: '#3e2708',
		caseTextColor: '#3e2708',
		secondaryTextColor: '#fff8ef',
		offTextColor: '#8a6f46',
		secondaryOffTextColor: '#5b3705',
		tertiaryOffTextColor: '#f5ddbc',
		caseSelectedColor: '#e5ebf3',
	},
	{
		themeTitle: 'CCS Jungle',
		themeDescription: 'We don\'t have a very good explanation for this one.',
		primaryColor: '#4c6c4c',
		secondaryColor: '#a4ad27',
		primaryBackgroundColor: '#543e2f',
		secondaryBackgroundColor: '#a4886b',
		tertiaryBackgroundColor: '#293928',
		offBackgroundColor: '#cecece',
		textColor: '#dfc3a2',
		caseTextColor: '#f5ddc0',
		secondaryTextColor: '#f9f0e7',
		offTextColor: '#ddcab3',
		secondaryOffTextColor: '#ddb68d',
		tertiaryOffTextColor: '#dfc3a2',
		caseSelectedColor: '#a88055',
	}
]

class App extends React.Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			timedExam: true,// Timed Exam checkbox
			applicationView: 'login',
			customTimeLimit: 'none',
			networkLag: 'none',
			email: '',
			emailConfirmation: '',
			password: '',
			firstName: '',
			lastName: '',
			demoHear: 'google',
			otherHear: '',
			newPassword: '',
			confirmPassword: '',
			loginErrorCount: 0,
			caseListHeaderError: '',
			userProfile: {},
			termsOfService: false,
			authenticationError: false,
			systemMessage: '',
			demoError: '',
			caseguid: '',
			startCaseData: {},
			feedbackContent: '',
			feedbackErrorMessage: '',
			feedbackMessage: '',
			production: true,
			route: 'https://app.ccscases.com',
			showLoading: false,
			fetchOutstanding: false,
			caseListSort: 'id',
			caseListReversed: false,
			showCaseTitle: true,
			showCaseDiagnosis: false,
			showSubscriptionEndWarning: false,
			subscriptionDaysRemaining: 0,
			displayCaseList: false,
			hiddenCases: [],
			primaryColor: '#0B335D',
			showNewCaseSelector: true,
			hideCasePopup: true,
			updateDisplayedCasesCallback: null,
			unfilteredCaseList: [],
			loadingCaseList: false,
			displayConfirmationPopup: false,
			confirmationPopupOptions: null,
			popupLoading: false,
			selectedCase: -1,
			selectedTheme: 0,
			themeChangeIndex: -1,
			initialSettingsPage: null,
			caseListScrollPosition: 0,
			continueCaseLoading: false,
			showNewCaseSelectorPopup: false,
			newCaseSelectorPopupConfirmLoading: false,
			newCaseSelectorPopupCancelLoading: false,
			gradingMode: 'complete',
			pageHeight: window.innerHeight,
			enableAnswerPeeking: false,
			windowsSystem: this.isUserOnWindows(),
			printReceiptHTML: null,
			categories: [
                {
                    icon: faStethoscope,
                    name: "Internal Medicine",
                    checked: true
                },
                {
                    icon: faBrain,
                    name: "Neurology",
                    checked: true
                },
                {
                    icon: faVenus,
                    name: "OB/GYN",
                    checked: true
                },
                {
                    icon: faChild,
                    name: "Pediatrics",
                    checked: true
                },
                {
                    icon: faTruckMedical,
                    name: "Emergency Medicine",
                    checked: true
                },
                {
                    icon: faHeadSideBrain,
                    name: "Psychiatry",
                    checked: true
                }
            ],
            hideCompleted: false,
            hideIncomplete: false,
			sortOrder: 'Id',
			sortDirection: 'Ascending'
		}

		this.fetchChangePassword = this.fetchChangePassword.bind(this);
		this.logout = this.logout.bind(this);
		this.newPasswordChange = this.newPasswordChange.bind(this);
		this.confirmPasswordChange = this.confirmPasswordChange.bind(this);
		this.accountSettingsClick = this.accountSettingsClick.bind(this);
		this.otherHearChange = this.otherHearChange.bind(this);
		this.demoHearChange = this.demoHearChange.bind(this);
		this.firstNameChange = this.firstNameChange.bind(this);
		this.lastNameChange = this.lastNameChange.bind(this);
		this.demoClick = this.demoClick.bind(this);
		this.emailChange = this.emailChange.bind(this);
		this.passwordChange = this.passwordChange.bind(this);
		this.networkLagChange = this.networkLagChange.bind(this);
		this.viewCaseGrades = this.viewCaseGrades.bind(this);
		this.customTimeLimitChange = this.customTimeLimitChange.bind(this);
		this.updateLocation = this.updateLocation.bind(this);
		this.fetchStartCase = this.fetchStartCase.bind(this);
		this.timedExamToggle = this.timedExamToggle.bind(this);
	}

	componentDidMount() {
		this.getRoute()
		// add page height listener
		window.addEventListener('resize', () => this.updatePageHeight());
	}

	isUserOnWindows = () => {
		return navigator.userAgent.indexOf('Windows') > -1;
	}

	updatePageHeight = () => {
		this.setState({pageHeight: window.innerHeight})
	}

	getRoute = () => {
		let route = ''
		if (process.env.REACT_APP_PRODUCTION !== 'false') {
			route = 'https://app.ccscases.com'
			this.setState({production: true})
		} else {
			route = `http://localhost:${process.env.REACT_APP_NETWORK_PORT ? process.env.REACT_APP_NETWORK_PORT : 80}`
			this.setState({production: false})
		}
		this.setState({route})
	}

	changeTheme = async (theme, saveChange = true) => {
		this.setState({selectedTheme: theme, primaryColor: THEMES[theme].primaryColor})

		document.documentElement.style.setProperty('--new-primary-color', THEMES[theme].primaryColor)
		document.documentElement.style.setProperty('--new-secondary-color', THEMES[theme].secondaryColor)
		document.documentElement.style.setProperty('--new-primary-background-color', THEMES[theme].primaryBackgroundColor)
		document.documentElement.style.setProperty('--new-secondary-background-color', THEMES[theme].secondaryBackgroundColor)
		document.documentElement.style.setProperty('--new-tertiary-background-color', THEMES[theme].tertiaryBackgroundColor)
		document.documentElement.style.setProperty('--new-off-background-color', THEMES[theme].offBackgroundColor)
		document.documentElement.style.setProperty('--new-text-color', THEMES[theme].textColor)
		document.documentElement.style.setProperty('--new-case-text-color', THEMES[theme].caseTextColor)
		document.documentElement.style.setProperty('--new-secondary-text-color', THEMES[theme].secondaryTextColor)
		document.documentElement.style.setProperty('--new-off-text-color', THEMES[theme].offTextColor)
		document.documentElement.style.setProperty('--new-secondary-off-text-color', THEMES[theme].secondaryOffTextColor)
		document.documentElement.style.setProperty('--new-tertiary-off-text-color', THEMES[theme].tertiaryOffTextColor)
		document.documentElement.style.setProperty('--case-selected-color', THEMES[theme].caseSelectedColor)
		document.querySelector("meta[name=theme-color]").setAttribute("content", THEMES[theme].tertiaryBackgroundColor)

		if (saveChange) {
			this.setState({themeChangeIndex: theme})
			const response = await fetch(`${this.state.route}/changetheme.webapi`, {
				method: 'POST',
				headers: {
					'Token': this.state.userData.Token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					Theme: theme,
					CustomerId: this.state.userData.CustomerId
				})
			})
			this.setState({themeChangeIndex: -1})

			if (response.status === 401) {
				return this.setState({authenticationError: true})
			}

			if (response.ok) {
				const apiMessage = await response.text()
				console.log(apiMessage)
			} else {
				console.log('error', response)
			}
		}
	}

	logout() {
		window.location.reload();
	}

	fetchChangePassword() {
		if (this.state.newPassword !== this.state.confirmPassword) {
			this.setState({changePasswordMessage: 'New Password and Confirm Password must match'});
		}
		else {
			this.setState({fetchOutstanding: true})
			fetch(`${this.state.route}/changepassword.webapi`, {
				method: 'POST',
				headers: {
					'Token': this.state.userData.Token,
					'Content-Type': 'text/plain',
				},
				body: JSON.stringify({
					customerId: this.state.userData.CustomerId,
					oldPassword: this.state.password,
					newPassword: this.state.newPassword,
				})
			})
			.then(response => {
				this.setState({fetchOutstanding: false})
				if (response.status === 401) {
					this.setState({authenticationError: true})
					throw new Error('Authentication Error')
				} else {
					return response.text()
				}
			})
			.then(response => {
				let result = response

				//Attempt sending logs
				fetchSubmitLogs(null, null)

				if (result.includes('Password updated')) {
					this.setState({
						password: '',
						newPassword: '',
						confirmPassword: '',
					});
				}
				result = result.replace('{', '');
				result = result.replace('}', '');
				this.setState({changePasswordMessage: result});
			})
			.catch(error => {
				this.setState({fetchOutstanding: false})
				// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
				// 	this.setState({authenticationError: true})
				// }
				console.log('error', error);
				logFetchError(error, this.state.userProfile, this.state.userData, 'fetchChangePassword')
			});
		}
	}

	newPasswordChange(event) {
		this.setState({newPassword: event.target.value});
	}

	confirmPasswordChange(event) {
		this.setState({confirmPassword: event.target.value});
	}

	accountSettingsClick() {
		this.setState({applicationView: 'accountSettings'});
	}

	otherHearChange(event) {
		this.setState({otherHear: event.target.value});
	}

	demoHearChange(event) {
		this.setState({demoHear: event.target.value});
	}

	firstNameChange(event) {
		this.setState({firstName: event.target.value});
	}

	lastNameChange(event) {
		this.setState({lastName: event.target.value});
	}

	demoClick() {
		this.setState({demoSignup: true});
	}

	emailChange(event) {
		this.setState({email: event.target.value});
	}

	passwordChange(event) {
		this.setState({password: event.target.value});
	}

	networkLagChange(event) {
		this.setState({networkLag: event.target.value});
	}

	closeGrading = () => {
		this.setState({
			applicationView: 'caseList',
			grading: null,
			feedbackMessage: ''
		});
	}

	viewCaseGrades(id, reviewLater) {
		this.setState({
			grading: false,
			applicationView: 'grading',
			reviewLater: reviewLater,
			selectedCase: id,
			gradingMode: 'complete'
		});
	}

	peekCaseGrades = (id, reviewLater) => {
		this.setState({
			grading: false,
			applicationView: 'grading',
			reviewLater: reviewLater,
			selectedCase: id,
			gradingMode: 'peek'
		});
	}

	customTimeLimitChange(event) {
		this.setState({customTimeLimit: event.target.value});
	}

	fetchGrading = async (reviewLater, selectedCaseId = this.state.selectedCase) => {
		await fetch(`${this.state.route}/grading.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.state.userData.CustomerId,
				caseguid: this.state.simulationData.caseguid,
				caseId: selectedCaseId,
			})
		})
		.then(response => {
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(async response => {

			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			await this.fetchCaseList()
			this.viewCaseGrades(selectedCaseId, reviewLater)

			// let data = response
			// this.setState({
			// 	grading: JSON.parse(data),
			// 	applicationView: 'grading',
			// 	reviewLater: reviewLater,
			// 	gradingMode: 'complete'
			// });
		})
		.catch(error => {
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.setState({authenticationError: true})
			// }
			console.log('error', error);
			logFetchError(error, this.state.userProfile, this.state.userData, 'fetchGrading')
		});
	}

	updateLocation(id) {
		let temp = this.state.simulationData;
		temp.CaseLocationId = id;
		switch (id) {
			case 1:
				temp.CaseLocationDesc = 'Emergency Department';
				break;
			case 2:
				temp.CaseLocationDesc = 'Office';
				break;
			case 3:
				temp.CaseLocationDesc = 'Intensive Care Unit';
				break;
			case 4:
				temp.CaseLocationDesc = 'Home';
				break;
			case 5:
				temp.CaseLocationDesc = 'Inpatient Unit';
				break;
			default:
				temp.CaseLocationDesc = '';
		}
		this.setState({simulationData: temp});
	}

	/*
	startcase api call
	function called when Run Case is clicked
	*/
	async fetchStartCase(caseData, openIncompleteCaseWindow) {
		let startCaseData = {
			completedOrders: [],
			currentLocation: 2,
			currentOrders: [],
			currentPatientUpdate: '',
			currentPhysicalExamId: 10,
			currentState: 'B0',
			currentVirtualTime: '',
			currentVitalsId: 9,
			timeToPatientUpdate: 0,
			popupQueue: ['introduction', 'vitals', 'history'],
			simulationOptionSelected: -1,
			centerContent: false,
			elapsedSimulatedCaseTime: {
				days: 0,
				hours: 0,
				minutes: 0
			},
			physicalResults: [],
			currentActualTime: 0,
			caseEnds: false,
			patientUpdates: [],
			vitalsResults: []
		}
		await fetch(`${this.state.route}/startcase.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.state.userData.CustomerId,
				caseId: caseData.ID
			})
		})
		.then(response => {
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(response => {

			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			let result = response
			if (result.includes('Error: ')) {
				this.setState({caseListHeaderError: result})
			} else {
				let simulationData = JSON.parse(result);
				this.setState({caseguid: simulationData.caseguid})
				let date = new Date(`${simulationData.InitialStartTime}Z`)
				simulationData.InitialStartTime = {
					days: getDaysFromDate(date),
					hours: date.getUTCHours(),
					minutes: date.getUTCMinutes(),
				}

				startCaseData.elapsedSimulatedCaseTime = {
					days: simulationData.InitialStartTime.days,
					hours: simulationData.InitialStartTime.hours,
					minutes: simulationData.InitialStartTime.minutes
				}

				if (simulationData.StartMed) {
					let startingOrders = JSON.parse(simulationData.CurrentOrders)
					for(let i = 0; i < startingOrders.length; ++i) {
						let reportTime = new Date(`${startingOrders[i].ReportTime}Z`)
						let timeOrdered = new Date(`${startingOrders[i].TimeOrdered}`)
						let currentVirtualTime = new Date(`${startingOrders[i].CurrentVirtualTime}Z`)

						if (startingOrders[i].ReportTime === "0001-01-01T00:00:00") {
							startingOrders[i].ReportTime = null
						} else {
							startingOrders[i].ReportTime = {
								days: getDaysFromDate(reportTime),
								hours: reportTime.getUTCHours(),
								minutes: reportTime.getUTCMinutes()
							}
						}

						startingOrders[i].TimeOrdered = {
							days: getDaysFromDate(timeOrdered),
							hours: timeOrdered.getUTCHours(),
							minutes: timeOrdered.getUTCMinutes()
						}

						startingOrders[i].CurrentVirtualTime = {
							days: getDaysFromDate(currentVirtualTime),
							hours: currentVirtualTime.getUTCHours(),
							minutes: currentVirtualTime.getUTCMinutes()
						}
					}
					startCaseData.currentOrders = startingOrders
				}
				if (simulationData.ialist.length === 0) {
					startCaseData.currentLocation = simulationData.CaseLocationId
					startCaseData.currentVirtualTime = simulationData.InitialStartTime
					this.setState({
						startCaseData,
						caseData: caseData,
						caseListHeaderError: '',
						simulationData: simulationData,
						applicationView: 'simulation'
					}, () => {
						this.setState({})
					})
				} else {
					let incompleteCaseList = [...simulationData.ialist]
					this.setState({
						caseData: caseData,
						caseListHeaderError: '',
						simulationData: simulationData
					})
					// Sort incompleteCaseList by date
					incompleteCaseList.sort((a, b) => {
						let aDate = new Date(`${a.TimeAttempted}Z`)
						let bDate = new Date(`${b.TimeAttempted}Z`)
						return bDate - aDate
					})
					openIncompleteCaseWindow(incompleteCaseList, () => {
						this.setState({startCaseData, applicationView: 'simulation'})
					})
				}
			}
		})
		.catch(error => {
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.setState({authenticationError: true})
			// }
			console.log('error', error);
			logFetchError(error, this.state.userProfile, this.state.userData, 'fetchStartCase')
		});
	}

	confirmIncompleteCaseStart = (selectedIncompleteCase, incompleteCaseList) => {
		let incompleteStartData = {}
		this.setState({continueCaseLoading: true})
		fetch(`${this.state.route}/incompletestart.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				caseguid: this.state.caseguid,
				CurrentStateId: incompleteCaseList[selectedIncompleteCase].CurrentStateId
			})
		})
		.then(response => {
			this.setState({continueCaseLoading: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
				const decoder = new TextDecoder('utf-8')
				let result = ''
				let reader = response.body.getReader()
				reader
					.read()
					.then(function processText({ done, value }) {
						if (done) {
							incompleteStartData = JSON.parse(result)
							return
						}
					
						result += decoder.decode(value);
					
						// Read some more, and call this function again
						return reader.read().then(processText);
					})
					.then(() => {

						//Attempt sending logs
						fetchSubmitLogs(this.state.userProfile, this.state.userData)
						let temp = {...this.state.simulationData}
						//Determine User's current simulated location
						temp.CaseLocationId = incompleteStartData.CurrentLocation
						let locations = ['Emergency Department', 'Office', 'Intensive Care Unit', 'Home', 'Inpatient Unit']
						temp.CaseLocationDesc = locations[temp.CaseLocationId - 1]
						
						//Format the current simulated time for the case
						let date = new Date(`${incompleteStartData.CurrentVirtualTime}Z`);
	
						let elapsedSimulatedCaseTime = {
							days: getDaysFromDate(date),
							hours: date.getUTCHours(),
							minutes: date.getUTCMinutes(),
						}
	
						//Retrieve relevant action log data including patientUpdates, currentActualTime and physical results by calling getActionLogData (imported)
						let { patientUpdates, currentActualTime, physicalResults, vitalsResults } = getActionLogData(JSON.parse(incompleteStartData.ActionLog))
	
						//Format the current orders by calling formatCurrentOrders (imported)
						let currentOrders = formatCurrentOrders(JSON.parse(incompleteStartData.CurrentOrders))
	
						//Format completed orders by calling formatCompletedOrders (imported)
						let completedOrders = formatCompletedOrders(JSON.parse(incompleteStartData.CompletedOrders))
	
						//Construct startCaseData. This is used primarily to instantiate the simulation state with the user's case progress
						let startCaseData = {
							completedOrders,
							currentLocation: incompleteStartData.CurrentLocation,
							currentOrders,
							currentPatientUpdate: incompleteStartData.currentPatientUpdate,
							currentPhysicalExamId: incompleteStartData.currentPhysicalExamId,
							currentState: incompleteStartData.CurrentState,
							currentVirtualTime: temp.InitialStartTime,
							currentVitalsId: incompleteStartData.CurrentVitalsId,
							timeToPatientUpdate: incompleteStartData.timeToPatientUpdate,
							popupQueue: [],
							simulationOptionSelected: 1,
							centerContent: 'reviewOrders',
							elapsedSimulatedCaseTime,
							physicalResults,
							currentActualTime: currentActualTime === null ? 0 : currentActualTime.getMinutes(),
							caseEnds: incompleteStartData.CaseEnded,
							patientUpdates,
							vitalsResults
						}
						this.setState({simulationData: temp, startCaseData, applicationView: 'simulation'})
					})
					.catch((error) => {
						this.setState({continueCaseLoading: false})
						console.log(error)
						logFetchError(error, this.state.userProfile, this.state.userData, 'confirmIncompleteCaseStart')
					})
			}
		})
		.catch((error) => {
			this.setState({fetchOutstanding: false})
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'confirmIncompleteCaseStart FetchError')
		})
	}

	/*
	toggles the state of the Timed Exam checkbox
	*/
	timedExamToggle() {
		this.setState({timedExam: !this.state.timedExam});
	}

	formatTimeHandler = (date) => {
		let formattedDate = new Date(date)
		let hours = formattedDate.getUTCHours()
		let minutes = formattedDate.getUTCMinutes()
		let timeString = `${(hours === 0 || hours === 12) ? '12' : hours > 12 ? hours - 12 : hours}:${minutes < 10 ? `0${minutes}` : minutes } ${hours >= 12 ? 'PM' : 'AM'}`
		return timeString
	}

	formatDateHandler = (date) => {
		let formattedDate = new Date(date)
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		let dateString = `${months[formattedDate.getUTCMonth()]} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}, ${this.formatTimeHandler(date)}`
		return dateString
	}

	updateAccountDetails = (user) => {
		let userProfile = {
			firstName: user.CustomerFirstName,
			lastName: user.CustomerLastName,
			userName: user.CustomerUserName,
			subscriptionEndDate: this.formatDateHandler(`${user.LicenseExpiration}Z`),
			accountCreationDate: this.formatDateHandler(`${user.DateCustCreated}Z`),
			demo: user.Demo,
			caseCompletionLimit: user.LimitFiveCases,
			selectedTimeZone: user.TimeZone === "" ? "-7" : user.TimeZone,
			hideHighYield: user.TurnOffHighYield,
			animationsDisabled: user.AnimationsDisabled,
			DontShowHideCasePopup: user.DontShowHideCasePopup,
			SeenCaseSelectorPopup: user.SeenCaseSelectorPopup,
			NewCaseSelector: user.NewCaseSelector,
			DisableFeedback: user.DisableFeedback,
			ModernList: user.ModernList,
			ClassicGrading: user.ClassicGrading,
			ShowLastCaseAttempt: user.ShowLastCaseAttempt
		}
		let expirationDate = new Date(user.LicenseExpiration)
		let todaysDate = new Date()
		let dateDiff = new Date(expirationDate - todaysDate)
		let daysDiff = dateDiff.getTime() / 1000 / 60 / 60 / 24

		let showNewCaseSelectorPopup
		if (process.env.REACT_APP_FORCE_CASE_SELECTOR_POPUP !== 'false') {
			showNewCaseSelectorPopup = true
		} else {
			showNewCaseSelectorPopup = userProfile.SeenCaseSelectorPopup ? false : true
		}

		this.setState({
			userProfile,
			showLoading: user.LoadingPopUp ? true : false,
			showSubscriptionEndWarning: daysDiff < 7 ? true : false,
			subscriptionDaysRemaining: Math.floor(daysDiff),
			showNewCaseSelectorPopup: showNewCaseSelectorPopup,
		})
	}

	showLoadingHandler = async () => {
		let newValue = !this.state.showLoading
		this.setState({showLoading: newValue})
		await fetch(`${this.state.route}/loadingpopup.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				LoadingPopUp: newValue
			})
		})
		.then(() => {
		})
		.catch((error) => {
			this.setState({showLoading: !newValue})
			logFetchError(error, this.state.userProfile, this.state.userData, 'showLoadingHandler')
		})
	}

	hideHighYieldHandler = async () => {
		let userProfile = {...this.state.userProfile}
		let newValue = !userProfile.hideHighYield
		userProfile.hideHighYield = newValue
		this.setState({userProfile})
		await fetch(`${this.state.route}/turnoffhighyield.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				TurnOffHighYield: newValue
			})
		})
		.then(() => {

		})
		.catch((error) => {
			userProfile.hideHighYield = !newValue
			this.setState({userProfile})
			logFetchError(error, this.state.userProfile, this.state.userData, 'hideHighYieldHandler')
		})
	}

	updateSelectedTimeZone = (value) => {
		let initialTimeZone = this.state.userProfile.selectedTimeZone
		let userProfile = this.state.userProfile
		userProfile.selectedTimeZone = value
		let userData = this.state.userData
		userData.TimeZone = value
		this.setState({userProfile, userData})
		fetch(`${this.state.route}/updatetimezone.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				timeZone: value
			})
		})
		.then((response) => {
		})
		.catch((error) => {
			let userProfile = this.state.userProfile
			userProfile.selectedTimeZone = initialTimeZone
			this.setState({userProfile})
			logFetchError(error, this.state.userProfile, this.state.userData, 'updateSelectedTimeZone')
		})
	}

	updateHiddenCases = (hiddenCases) => {
		this.setState({hiddenCases})
	}

	unhideCase = async (caseId) => {
		let hiddenCases = this.state.hiddenCases
		let index = hiddenCases.findIndex((hiddenCase) => {
			return hiddenCase.id === caseId
		})

		if (index <= -1) {
			return
		}

		let body = JSON.stringify({
			CaseNumberToHide: caseId,
			CustomerId: this.state.userData.CustomerId,
			RemoveCase: true
		})

		await fetch(`${this.state.route}/addremovehiddencases.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			hiddenCases.splice(index, 1)
			this.setState({hiddenCases})
		})
		.catch((error) => {
			logFetchError(error, this.state.userProfile, this.state.userData, 'unhideCase')
		})
	}

	toggleNewCaseSelector = async (newCaseSelector = !this.state.userProfile.NewCaseSelector) => {
		let userProfile = {...this.state.userProfile}
		userProfile.NewCaseSelector = newCaseSelector

		let caseSelectorFetch = await fetch(`${this.state.route}/togglenewcaseselector.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				NewCaseSelector: newCaseSelector,
				CustomerId: this.state.userData.CustomerId
			})
		})

		if (caseSelectorFetch.ok) {
			let caseSelectorResponse = await caseSelectorFetch.text()
			console.log(caseSelectorResponse)
			this.setState({userProfile})
		} else if (caseSelectorFetch.status === 401) {
			this.setState({authenticationError: true})
		} else {
			console.log('Error: ' + caseSelectorFetch.status)
		}
	}

	reviewLaterToggle = (id) => {
		let tempJson = this.state.unfilteredCaseList;
		for (let i = 0; i < tempJson.length; i++) {
			if (tempJson[i].ID === id) {
				tempJson[i].ReviewLaterFlag = !tempJson[i].ReviewLaterFlag;
			}
		}
		this.setState({unfilteredCaseList: tempJson}, () => {
			if (this.state.updateDisplayedCasesCallback !== null) {
				this.state.updateDisplayedCasesCallback()
			}
        });
	}

	getCaseCategoryFromId = (caseId, caseList = this.state.unfilteredCaseList) => {
        for (let i = 0; i < caseList.length; i++) {
            if (caseList[i].ID === caseId) {
                return caseList[i].CASECATEGORYDESC
            }
        }
    }

	fetchCaseList = async (login = false) => {
        this.setState({loadingCaseList: true})
		await fetch(`${this.state.route}/listcasesupdate.webapi`, {
			method: 'GET',
			headers: {
				'Token': this.state.userData.Token,
			},
		})
		.then(response => {
			if (response.status === 401) {
                this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
                return response.text()
			}
		})
		.then(response => {
			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

            let results = JSON.parse(response)

            // Merge each result with its corresponding id in the static json
            let caseList = []
            for (let i = 0; i < results.length; i++) {
                let userCaseData = results[i]
                let staticCaseData = StaticJsonCaseList.lc[i]
                let newCaseObject = {...userCaseData, ...staticCaseData}
                caseList.push(newCaseObject)
            }

			results = {...this.state.userData}
			results.lc = caseList

			let hiddenCases = []
			if (this.state.userData.HiddenCases.length > 0) {
				hiddenCases = JSON.parse(this.state.userData.HiddenCases)
				hiddenCases = hiddenCases.map(hiddenCase => parseInt(hiddenCase))
			}

            let hiddenCasesAndCategories = []
            for (let i = 0; i < hiddenCases.length; i++) {
                let hiddenCase = hiddenCases[i]
                hiddenCasesAndCategories.push({
                    id: hiddenCase,
                    category: this.getCaseCategoryFromId(hiddenCases[i], results.lc)
                })
            }
            
            this.updateHiddenCases(hiddenCasesAndCategories)
			if (login) {
				// console.log(results)
				this.updateAccountDetails(results)
			}
            this.setState({unfilteredCaseList: results.lc, loadingCaseList: false}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
                	this.state.updateDisplayedCasesCallback()
				}
            })
		})
		.catch(error => {
            this.setState({loadingCaseList: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log('error', error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchCaseList')
		});
    }

	setDisplayedCasesCallback = (callback) => {
		this.setState({updateDisplayedCasesCallback: callback})
	}

	unhideAllCasesHandler = async () => {
		let hiddenCases = this.state.hiddenCases
		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			ResetHiddenCases: true
		})

		await fetch(`${this.state.route}/resethiddencases.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			console.log(response)
			hiddenCases = []
			this.setState({hiddenCases})
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'unhideAllCasesHandler')
		})
	}

	displayConfirmationPopup = (confirmationPopupOptions) => {
		this.setState({displayConfirmationPopup: true, confirmationPopupOptions})
	}

	hideCasePopupCheckboxHandler = () => {
        let confirmationPopupOptions = {...this.state.confirmationPopupOptions}
        confirmationPopupOptions.children = (
            <div className='confirmationPopupCheckboxWrapper' onClick={this.hideCasePopupCheckboxHandler}>
                <div className='checkboxContainer' key={this.state.hideCasePopup}>
                    {this.state.hideCasePopup && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                </div>
                <p className='confirmationPopupCheckboxLabel'>Don't show this again</p>
            </div>
        )
		this.setState({hideCasePopup: !this.state.hideCasePopup})
        this.setState({confirmationPopupOptions})
    }

	setSelectedCase = (caseId, callback = null) => {
		this.setState({selectedCase: caseId}, () => {
			if (callback !== null) {
				callback()
			}
		})
	}

	disableAnimationsHandler = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.animationsDisabled = !userProfile.animationsDisabled

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			animationsDisabled: userProfile.animationsDisabled
		})

		await fetch(`${this.state.route}/toggleanimationsdisable.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userProfile})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'disableAnimationsHandler')
		})
	}

	viewHiddenCases = () => {
		this.setState({applicationView: 'manageHiddenCases'})
	}

	handleLogin = (stateData) => {
		// console.log(stateData)
		let state = {...this.state, ...stateData}
		state.applicationView = 'caseList'
		this.changeTheme(Number(stateData.userProfile.Theme), false)
		this.setState(state, () => {
			this.fetchCaseList(true)
		})
	}

	seenCaseSelectorHandler = async (userDecision) => {
		this.setState({
			newCaseSelectorPopupConfirmLoading: userDecision ? true : false,
			newCaseSelectorPopupCancelLoading: userDecision ? false : true
		})
		let userProfile = {...this.state.userProfile}
		userProfile.SeenCaseSelectorPopup = true
		await fetch(`${this.state.route}/toggleseencaseselectorpopup.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				SeenCaseSelectorPopup: true
			})
		})
		.then((response) => {
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then((response) => {
			console.log(response)
			this.setState({userProfile}, async () => {
				await this.toggleNewCaseSelector(userDecision)
				this.setState({
					showNewCaseSelectorPopup: false,
					newCaseSelectorPopupConfirmLoading: false,
					newCaseSelectorPopupCancelLoading: false,
				})
			})
		})
		.catch((error) => {
			console.log(error)
			this.setState({
				newCaseSelectorPopupConfirmLoading: false,
				newCaseSelectorPopupCancelLoading: false
			})
			logFetchError(error, this.state.userProfile, this.state.userData, 'seenCaseSelectorHandler')
		})
	}

	setGradingModeAndUpdateCases = (newMode, callback) => {
		this.setState({gradingMode: newMode}, async () => {
			await this.fetchCaseList()
			callback()
		})
	}

	updateReviewLater = () => {
    let caseIndex = this.state.unfilteredCaseList.findIndex(caseData => caseData.ID === this.state.selectedCase)
		let unfilteredCaseList = [...this.state.unfilteredCaseList]
		unfilteredCaseList[caseIndex].ReviewLaterFlag = !unfilteredCaseList[caseIndex].ReviewLaterFlag

		this.setState({unfilteredCaseList})
	}

	printReceipt = () => {
		window.print()
	}

	updateCategories = (categories, callback) => {
		this.setState({categories}, callback)
	}

	toggleHideCompleted = () => {
        if (this.state.hideIncomplete && !this.state.hideCompleted) {
            this.setState({hideIncomplete: false, hideCompleted: true}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        } else {
            this.setState({hideCompleted: !this.state.hideCompleted}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        }
    }

    toggleHideIncomplete = () => {
        if (this.state.hideCompleted && !this.state.hideIncomplete) {
            this.setState({hideCompleted: false, hideIncomplete: true}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        } else {
            this.setState({hideIncomplete: !this.state.hideIncomplete}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        }
    }

	toggleDisableFeedback = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.DisableFeedback = !userProfile.DisableFeedback

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			DisableFeedback: userProfile.DisableFeedback
		})

		await fetch(`${this.state.route}/toggledisablefeedback.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userProfile})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'toggleDisableFeedback')
		})
	}

	toggleCaseListStyle = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.ModernList = !userProfile.ModernList

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			ModernList: userProfile.ModernList
		})
		// console.log(body)

		await fetch(`${this.state.route}/togglemodernlist.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userProfile})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			// console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'toggleCaseListStyle')
		})
	}

	toggleClassicGrading = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.ClassicGrading = !userProfile.ClassicGrading

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			ClassicGrading: userProfile.ClassicGrading
		})

		await fetch(`${this.state.route}/toggleclassicgrading.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userProfile})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'toggleClassicGrading')
		})
	}

	toggleSortDirection = (updateCaseList = true) => {
		if (this.state.sortDirection === 'Ascending') {
			this.setState({sortDirection: 'Descending'}, () => {
				if (this.state.updateDisplayedCasesCallback !== null && updateCaseList) {
					this.state.updateDisplayedCasesCallback()
				}
			})
		} else {
			this.setState({sortDirection: 'Ascending'}, () => {
				if (this.state.updateDisplayedCasesCallback !== null && updateCaseList) {
					this.state.updateDisplayedCasesCallback()
				}
			})
		}
	}

	toggleShowLastCaseAttempt = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.ShowLastCaseAttempt = !userProfile.ShowLastCaseAttempt

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			ShowLastCaseAttempt: userProfile.ShowLastCaseAttempt
		})

		await fetch(`${this.state.route}/toggleshowlastcaseattempt.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			this.setState({userProfile})
			if (this.state.sortOrder === 'AverageGrade' && userProfile.ShowLastCaseAttempt) {
				this.setState({sortOrder: 'LastCompletionGrade'})
			} else if (this.state.sortOrder === 'LastCompletionGrade' && !userProfile.ShowLastCaseAttempt) {
				this.setState({sortOrder: 'AverageGrade'})
			}
			console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'toggleShowLastCaseAttempt')
		})
	}

	render() {

		if (this.state.printReceiptHTML !== null) {
			return (
				<div className='printReceiptContainer'>
					<div dangerouslySetInnerHTML={{__html: this.state.printReceiptHTML}} />
					<div className='printReceiptButtonContainer'>
						<button className='printReceiptButton secondaryButton' onClick={() => this.setState({printReceiptHTML: null})}>Cancel</button>
						<button className='printReceiptButton primaryButton' onClick={this.printReceipt}>Print</button>
					</div>
				</div>
			)
		}

		// return <NewGrading />

		let applicationView;
		let loginLogo;

		// switch between the main application views
		if (this.state.applicationView === 'caseList') {
			if (this.state.userData) {
				if (!this.state.userProfile.NewCaseSelector) {
					applicationView = (
						<CaseList 
							updateAccountDetails={this.updateAccountDetails}
							setAuthenticationError={() => this.setState({authenticationError: true})}
							userData={this.state.userData}
							userProfile={this.state.userProfile}
							fetchStartCase={this.fetchStartCase}
							timedExam={this.state.timedExam}
							timedExamToggle={this.timedExamToggle}
							customTimeLimit={this.state.customTimeLimit}
							customTimeLimitChange={this.customTimeLimitChange}
							viewCaseGrades={this.viewCaseGrades}
							networkLag={this.state.networkLag}
							networkLagChange={this.networkLagChange}
							accountSettingsClick={this.accountSettingsClick}
							caseListHeaderError={this.state.caseListHeaderError}
							confirmIncompleteCaseStart={this.confirmIncompleteCaseStart}
							route={this.state.route}
							setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
							viewReportCard={() => this.setState({applicationView: 'reportCard'})}
							caseListSort={this.state.caseListSort}
							changeCaseListSort={(newSort) => this.setState({caseListSort: newSort})}
							showCaseTitle={this.state.showCaseTitle}
							toggleShowCaseTitle={() => this.setState({showCaseTitle: !this.state.showCaseTitle})}
							showCaseDiagnosis={this.state.showCaseDiagnosis}
							toggleShowCaseDiagnosis={() => this.setState({showCaseDiagnosis: !this.state.showCaseDiagnosis})}
							showSubscriptionEndWarning={this.state.showSubscriptionEndWarning}
							subscriptionDaysRemaining={this.state.subscriptionDaysRemaining}
							unfilteredCaseList={this.state.unfilteredCaseList}
							setSelectedCase={this.setSelectedCase}
							selectedCase={this.state.selectedCase}
							setCurrentScrollPosition={(scrollPosition) => this.setState({caseListScrollPosition: scrollPosition})}
							scrollPosition={this.state.caseListScrollPosition}
							primaryColor={this.state.primaryColor}
						/>
					)
				} else {
					applicationView = (
						<NewCaseList
							changePage={(newPage) => this.setState({applicationView: newPage})}
							updateAccountDetails={this.updateAccountDetails}
							userData={this.state.userData}
							userProfile={this.state.userProfile}
							setAuthenticationError={() => this.setState({authenticationError: true})}
							route={this.state.route}
							viewCaseGrades={this.viewCaseGrades}
							peekCaseGrades={this.peekCaseGrades}
							gradingMode={this.state.gradingMode}
							setGradingMode={this.setGradingModeAndUpdateCases}
							fetchStartCase={this.fetchStartCase}
							confirmIncompleteCaseStart={this.confirmIncompleteCaseStart}
							updateHiddenCases={this.updateHiddenCases}
							hiddenCases={this.state.hiddenCases}
							primaryColor={this.state.primaryColor}
							secondaryColor={THEMES[this.state.selectedTheme].secondaryColor}
							showCaseTitle={this.state.showCaseTitle}
							toggleShowCaseTitle={() => this.setState({showCaseTitle: !this.state.showCaseTitle})}
							showCaseDiagnosis={this.state.showCaseDiagnosis}
							toggleShowCaseDiagnosis={() => this.setState({showCaseDiagnosis: !this.state.showCaseDiagnosis})}
							hideCasePopup={this.state.hideCasePopup}
							toggleHideCasePopup={(newValue) => this.setState({hideCasePopup: newValue})}
							unfilteredCaseList={this.state.unfilteredCaseList}
							loadingCaseList={this.state.loadingCaseList}
							reviewLaterToggle={this.reviewLaterToggle}
							getCaseCategoryFromId={this.getCaseCategoryFromId}
							setDisplayedCasesCallback={this.setDisplayedCasesCallback}
							StaticJsonCaseList={StaticJsonCaseList}
							displayConfirmationPopup={this.displayConfirmationPopup}
							closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
							setPopupLoading={(newValue) => this.setState({popupLoading: newValue})}
							hideCasePopupCheckboxHandler={this.hideCasePopupCheckboxHandler}
							setSelectedCase={this.setSelectedCase}
							selectedCase={this.state.selectedCase}
							animationsDisabled={this.state.userProfile.animationsDisabled}
							viewHiddenCases={this.viewHiddenCases}
							continueCaseLoading={this.state.continueCaseLoading}
							loadingPopupStartCase={this.state.loadingPopupStartCase}
							key="CaseList"
							timedExam={this.state.timedExam}
							timedExamToggle={this.timedExamToggle}
							customTimeLimit={this.state.customTimeLimit}
							customTimeLimitChange={this.customTimeLimitChange}
							networkLag={this.state.networkLag}
							networkLagChange={this.networkLagChange}
							selectedTheme={THEMES[this.state.selectedTheme]}
							enableAnswerPeeking={this.state.enableAnswerPeeking}
							windowsSystem={this.state.windowsSystem}
							categories={this.state.categories}
							updateCategories={this.updateCategories}
							hideCompleted={this.state.hideCompleted}
							toggleHideCompleted={this.toggleHideCompleted}
							hideIncomplete={this.state.hideIncomplete}
							toggleHideIncomplete={this.toggleHideIncomplete}
							sortOrder={this.state.sortOrder}
							updateSortOrder={(newSortOrder, callback) => this.setState({sortOrder: newSortOrder}, callback)}
							caseListStyle={this.state.userProfile.ModernList}
							toggleCaseListStyle={this.toggleCaseListStyle}
							showSubscriptionEndWarning={this.state.showSubscriptionEndWarning}
							subscriptionDaysRemaining={this.state.subscriptionDaysRemaining}
							sortDirection={this.state.sortDirection}
							toggleSortDirection={this.toggleSortDirection}
						/>
					)
				}
			}
		}
		else if (this.state.applicationView === 'simulation') {
			applicationView = <Simulation
				caseData={this.state.caseData}
				setAuthenticationError={() => this.setState({authenticationError: true})}
				simulationData={this.state.simulationData}
				userData={this.state.userData}
				userProfile={this.state.userProfile}
				updateLocation={this.updateLocation}
				fetchGrading={this.fetchGrading}
				timedExam={this.state.timedExam}
				customTimeLimit={this.state.customTimeLimit}
				closeGrading={this.closeGrading}
				networkLag={this.state.networkLag}
				startCaseData={this.state.startCaseData}
				route={this.state.route}
				fetchOutstanding={this.state.fetchOutstanding}
				primaryColor={this.state.primaryColor}
			/>;
		}
		else if (this.state.applicationView === 'grading') {
			if (this.state.userProfile.ClassicGrading) {
				applicationView = <Grading
					grading={this.state.grading}// this is the json grading data if after case
					setAuthenticationError={() => this.setState({authenticationError: true})}
					userData={this.state.userData}
					userProfile={this.state.userProfile}
					caseData={this.state.caseData}
					selectedCase={this.state.selectedCase}
					closeGrading={this.closeGrading}
					reviewLater={this.state.reviewLater}
					route={this.state.route}
					setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
					primaryColor={this.state.primaryColor}
					gradingMode={this.state.gradingMode}
					EnableFeedback={this.state.gradingMode === 'peek' ? false : !this.state.userProfile.DisableFeedback}
					animationsDisabled={this.state.userProfile.animationsDisabled}
					updateReviewLater={this.updateReviewLater}
				/>
			} else {
				applicationView = <NewGrading
					grading={this.state.grading}
					setAuthenticationError={() => this.setState({authenticationError: true})}
					userData={this.state.userData}
					userProfile={this.state.userProfile}
					caseData={this.state.caseData}
					selectedCase={this.state.selectedCase}
					closeGrading={this.closeGrading}
					reviewLater={this.state.reviewLater}
					route={this.state.route}
					setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
					primaryColor={this.state.primaryColor}
					gradingMode={this.state.gradingMode}
					unfilteredCaseList={this.state.unfilteredCaseList}
					selectedTheme={THEMES[this.state.selectedTheme]}
					DisableFeedback={this.state.userProfile.DisableFeedback}
					displayConfirmationPopup={this.displayConfirmationPopup}
					closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
					updateReviewLater={this.updateReviewLater}
				/>
			}
		}
		else if (this.state.applicationView === 'login') {

			applicationView = (
				<LoginPage 
					handleLogin={this.handleLogin}
					route={this.state.route}
					primaryColor={this.state.primaryColor}
					selectedTheme={THEMES[this.state.selectedTheme]}
				/>
			)
		}
		else if (this.state.applicationView === 'accountSettings' || this.state.applicationView === 'manageHiddenCases') {
			applicationView = (
				<AccountSettings
					changePage={(newPage) => this.setState({applicationView: newPage})}
					applicationView={this.state.applicationView}
					logout={this.logout}
					userData={this.state.userData}
					userProfile={this.state.userProfile}
					updateSelectedTimeZone={this.updateSelectedTimeZone}
					updateHiddenCases={this.updateHiddenCases}
					updateShowLoading={this.updateShowLoading}
					showLoadingHandler={this.showLoadingHandler}
					hideHighYieldHandler={this.hideHighYieldHandler}
					hiddenCases={this.state.hiddenCases}
					selectedTimeZone={this.state.selectedTimeZone}
					showLoading={this.state.showLoading}
					hideHighYield={this.state.userProfile.hideHighYield}
					unhideCase={this.unhideCase}
					primaryColor={this.state.primaryColor}
					setAuthenticationError={() => this.setState({authenticationError: true})}
					route={this.state.route}
					key="AccountSettings"
					toggleNewCaseSelector={this.toggleNewCaseSelector}
					showNewCaseSelector={this.state.userProfile.NewCaseSelector}
					hideCasePopup={this.state.hideCasePopup}
					toggleHideCasePopup={() => this.setState({hideCasePopup: !this.state.hideCasePopup})}
					unhideAllCasesHandler={this.unhideAllCasesHandler}
					displayConfirmationPopup={this.displayConfirmationPopup}
					closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
					setPopupLoading={(newValue) => this.setState({popupLoading: newValue})}
					changeTheme={this.changeTheme}
					selectedTheme={this.state.selectedTheme}
					themes={THEMES}
					themeStyle={THEMES[this.state.selectedTheme]}
					themeChangeIndex={this.state.themeChangeIndex}
					disableAnimationsHandler={this.disableAnimationsHandler}
					DisableFeedback={this.state.userProfile.DisableFeedback}
					toggleDisableFeedback={this.toggleDisableFeedback}
					initialPage={this.state.initialSettingsPage}
					enableAnswerPeeking={this.state.enableAnswerPeeking}
					toggleAnswerPeeking={() => this.setState({enableAnswerPeeking: !this.state.enableAnswerPeeking})}
					setPrintReceiptHTML={(newValue) => this.setState({printReceiptHTML: newValue})}
					toggleClassicGrading={this.toggleClassicGrading}
					toggleShowLastCaseAttempt={this.toggleShowLastCaseAttempt}
				/>
			);
		}
		else if (this.state.applicationView === 'changePassword') {
			applicationView = (
				<div className={`login-screen ${!this.state.production && 'login-screen-test-background'}`}>
					<div className='login-box'>
						<div className='login-box-element'>
							<div>Current Password:</div>
							<input className='login-field' type='password' value={this.state.password} onChange={this.passwordChange}/>
						</div>
						<div className='login-box-element'>
							<div>New Password:</div>
							<input className='login-field' type='password' value={this.state.newPassword} onChange={this.newPasswordChange}/>
						</div>
						<div className='login-box-element'>
							<div>Confirm Password:</div>
							<input className='login-field' type='password' value={this.state.confirmPassword} onChange={this.confirmPasswordChange}/>
						</div>
						<div className='login-message'>{this.state.changePasswordMessage}</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Submit' onClick={this.fetchChangePassword}/>
						</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Cancel' onClick={() => this.setState({applicationView: 'accountSettings'})}/>
						</div>
					</div>
				</div>
			);
		}
		else if (this.state.applicationView === 'submitFeedback') {
			applicationView = (
				<div className={`login-screen ${!this.state.production && 'login-screen-test-background'}`}>
					<div className='login-box'>
						<div className='login-box-element'>
							<div>Describe your issue</div>
							<textarea className='feedbackTextarea' type='text' value={this.state.feedbackContent} onChange={(e) => this.setState({feedbackContent: e.target.value})}/>
						</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Submit' onClick={this.submitFeedbackHandler}/>
						</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Cancel' onClick={() => this.setState({applicationView: 'accountSettings', feedbackErrorMessage: ''})}/>
						</div>
						<div className='login-message'>{this.state.feedbackErrorMessage}</div>
					</div>
				</div>
			);
		}
		else if (this.state.applicationView === 'reportCard') {
			applicationView = <ReportCard
				userData={this.state.userData}
				userProfile={this.state.userProfile}
				route={this.state.route}
				returnToCaseList={() => this.setState({applicationView: 'caseList'})}
				setAuthenticationError={() => this.setState({authenticationError: true})}
			/>
		}
	
		return (
			<div className='app' style={{height: this.state.pageHeight}}>
				{loginLogo}
				{!this.state.production &&
					<div className='testModeWarningContainer'>
						<p className='testModeWarningText'>Test Mode</p>
					</div>
				}
				<AnimatePresence exitBeforeEnter>
					{applicationView}
				</AnimatePresence>
				<AnimatePresence exitBeforeEnter>
					{this.state.authenticationError &&
						<NewUnauthenticatedPopup
							animationsDisabled={this.state.userProfile.animationsDisabled}
						/>
					}
				</AnimatePresence>
				{(this.state.showLoading && this.state.fetchOutstanding) &&
					<div className='loadingPopupWrapper'>
						<div className='loadingPopupBackdrop' />
						<div className='loadingPopupContainer'>
							<Spinner size={40} singleColor={'#1c4670'}/>
							<p className='loadingPopupText'>Loading...</p>
						</div>
					</div>
				}
				<AnimatePresence>
					{this.state.showNewCaseSelectorPopup &&
						<NewCaseSelectorPopup
							seenCaseSelectorHandler={this.seenCaseSelectorHandler}
							key={"NewCaseSelectorPopup"}
							confirmLoading={this.state.newCaseSelectorPopupConfirmLoading}
							cancelLoading={this.state.newCaseSelectorPopupCancelLoading}
							primaryColor={this.state.primaryColor}
						/>
					}
				</AnimatePresence>
				<AnimatePresence>
                    {this.state.displayConfirmationPopup &&
                        <ConfirmationPopup
                            options={this.state.confirmationPopupOptions}
                            key={"ConfirmationPopup"}
                            popupLoading={this.state.popupLoading}
                            primaryColor={this.state.primaryColor}
							animationsDisabled={this.state.userProfile.animationsDisabled}
                        >
                            {this.state.confirmationPopupOptions.children}
                        </ConfirmationPopup>
                    }
                </AnimatePresence>
			</div>
		)
	}
}

export default App;
